<template>
    <div class="letter-spacing-auto cursor-default">
        <!-- Canvas stuff -->
        <transition name="fade-in">
            <ExploreCanvas :loaded="loadedCanvas" :deactivateClick="deactivateCanvasClick" />
        </transition>

        <!-- <div id="exploreCanvas" class="-z-5" @click="canvasClicked"></div>
        <div id="mouseBlob"></div> -->

        <!-- Action Buttons -->
        <!-- <router-link to="/" id="createMessageBtn" class="fixed top-5 right-5 bg-green-400 text-black font-black uppercase z-50 px-5 py-3 cursor-pointer shadow">
            Create message
        </router-link> -->
        <!-- Tutorial -->
        <transition name="fade-in">
            <div v-if="showTutorial" class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30">
                <div class="absolute top-0 left-0 w-full h-full bg-green-500 bg-opacity-30"></div>
                <!--  -->
                <div  class="absolute max-w-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-5 md:p-10 shadow-md  font-bold text-white text-center">
                    <div class="mb-2 flex justify-end items-center">
                        <span @click="closeTutorial" class="cursor-pointer ">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </span>
                    </div>
                    <div class="mb-10 uppercase font-black text-5xl">
                        <div class="mb-1">Malaria,</div>
                        <div class="mb-1"> we're too</div>
                        <div class="mb-1 text-green-500"> wise</div> 
                        <div class="mb-1">
                            for you.
                        </div>
                    </div>
                    <!--  -->
                    <div class="flex uppercase gap-3 justify-between items-center mb-5">
                        <div class=" flex flex-col items-center gap-1">
                            <span >
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </span>
                            <span>
                                Scroll to zoom
                            </span>
                        </div>
                        <div class=" flex flex-col items-center gap-1">
                            <span >
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                            </span>
                            <span>
                                Drag to explore
                            </span>
                        </div>
                        <div class=" flex flex-col items-center gap-1">
                            <span >
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"></path></svg>
                            </span>
                            <span>
                                Click to view message
                            </span>
                        </div>
                    </div>
                    <!--  -->
                    <p class="text-normal text-gray-400">
                        People from across the world have added their message to this global mural, follow the instructions above to explore.
                    </p>
                </div>
            </div>
        </transition>
        <!-- Selected message -->
        <transition name="fade-in">
            <div v-if="message" class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-10">
                <div class="absolute top-0 left-0 w-full h-full bg-green-500 bg-opacity-70"></div>
                <transition name="slide-up">
                    <div  class="absolute max-w-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-5 shadow-md uppercase font-bold text-white">
                        <div class="mb-2 flex justify-between items-center">
                            <h5 class="font-bold">Message  <span class="ml-3">#{{message.id}}</span></h5>
                            <span @click="closeMessage" class="cursor-pointer ">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </span>
                        </div>
                        <div class="mb-3">
                            <h3 class="text-3xl text-left flex flex-col ">
                                <div>Malaria, we're too</div>
                                <div>
                                    <span class="text-green-500"> {{ message.word }}</span> for you
                                </div>
                            </h3>
                        </div>

                        <div class="flex gap-5 justify-start items-center mb-3 flex-wrap">
                            <div class="flex items-center gap-1">
                                <span >
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"></path></svg>
                                </span>
                                <span>
                                    {{moment(message.createdAt).format("MMM Do YY")  }}
                                </span>
                            </div>
                            <div class="flex items-center gap-1">
                                <span >
                                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>
                                </span>
                                <span>
                                    {{message.country}}
                                </span>
                            </div>
                            
                            <div v-if="message.name" class="flex items-center gap-1">
                                <span >
                                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                </span>
                                <span>
                                    {{message.name}}
                                </span>
                            </div>
                        </div>
                        <!-- message image -->
                        <div class="w-full">
                            <img class="block w-full h-auto mx-auto" :src="message.image" :alt="message.word">
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import ExploreCanvas from '@/components/zeromalaria_components/ExploreCanvas.vue'

import time from '@/helpers/time.js'

import moment from 'moment'

export default {
    name: 'Explore',
    components: {
        ExploreCanvas,
    },
    props: ['id'],
    inject: ['ss'],
    data(){
        return {
            message: null,
            // deactivateCanvasClick: false,
            // showMessage: true,
            // showTutorial: false,
            // loadedCanvas: false,
            moment,
        }
    },
    async mounted(){
        console.log("\n\n<<>>** Explore **<<>>\n\n");

        await this.fetchMessages()
        this.ss.appLog("<< fetched");

        if(this.id){
            this.ss.appLog(">> prop", this.id);
            setTimeout(() => {
                
                // await time.sleep(3000)
                this.selectMessage(this.id)
            }, 5000);
        }

        this.loadedCanvas = true;
    },
    beforeRouteLeave(routeTo, routeFrom, next){
        this.ss.appLog('>>)) leaving Explore');
        this.ss.methods.deactivateCanvasClick(false);
        // Delete canvas node
        try{
            this.ss.appLog('>> removing canvas node');
            document.querySelector('#exploreCanvas').innerHTML = "";
        }catch(rmErr){
            this.ss.appLog('xx err removing canvas node');
        }
        next();
    },
    watch: {
        'id': async function(id){
            this.ss.appLog(">>> new ID", id);
            if(id){
                this.selectMessage(id)
            }
        }
    },
    computed: {
        // ? Returns a bool to deactivate the canvas clicks
        // ie : when a popup is active
        deactivateCanvasClick(){
            return this.ss.state.deactivateCanvasClick;
        },
        showSelectedMessage(){
            return this.showMessage && this.message
        },
    },
    methods: {
        canvasClicked($event){
            this.ss.appLog(">> canvasClicked", $event);
        },

        async closeMessage(){
            this.$router.push({name: 'Explore'})
            // hide modals
            this.message = null
            // this.showMessage = false
            await time.sleep(200)
            // then activate modal click
            this.ss.methods.deactivateCanvasClick(false);
        },
        closeTutorial(){
            this.showTutorial = false
        },
        async fetchMessages(){
            await this.ss.methods.fetchMessages()
        },
        selectMessage(id){
            this.ss.appLog(">> selectMessage", id);
            // ? Deactivate canvas click and show modals
            
            this.ss.methods.deactivateCanvasClick(true);
            // 
            this.message = this.ss.messages.find((m)=> m.id == id);
            this.ss.appLog(">> findMessage", this.message);
        },
    }
}
</script>