<template>
    <div>
        <div id="mouseBlob"></div>
        <div id="exploreCanvas"></div>
        <!--  -->
        <router-link
            :to="{ name: 'CreateMessage' }"
            id="createMessageBtn"
            class="fixed top-5 right-5 bg-green-500 text-black font-black uppercase z-50 px-5 py-3 cursor-pointer shadow"
        >
            Create message
        </router-link>
    </div>
</template>

<script>
import * as P5 from "p5";
import gsap from "gsap";
// import router from "vue";

var $$$deactivateCanvasClick = false;
var messages = [];

export default {
    name: "ExploreCanvas",
    props: {
        deactivateClick: {
            type: Boolean,
            // required: true,
            default: false,
        },
        loaded: {
            type: Boolean,
            default: false,
        },
    },
    inject: ["ss"],
    async mounted() {
        $$$deactivateCanvasClick = this.deactivateClick;
        messages = [];

        this.ss.appLog(">>>## messages");
        messages = await this.ss.methods.fetchMessages();

        // if (localStorage.messages) {
        //     try {
        //         if (messages.length == 0) {
        //             messages = JSON.parse(localStorage.messages);
        //         }
        //     } catch (jsonError) {
        //         messages = [];
        //         this.ss.appLog(">> error fetching JSON messages");
        //     }
        // }
        this.ss.appLog("<<<## messages ", messages.length);
        initCanvas(this.$router, this.loaded);
        gsap.from("#createMessageBtn", {
            opacity: 0,
            top: "-30px",
            ease: "power2",
            delay: 4,
            duration: 0.3,
        });
    },

    watch: {
        deactivateClick: function (val) {
            $$$deactivateCanvasClick = val;
        },
        "ss.methods.deactivateCanvasClick": function (val) {
            $$$deactivateCanvasClick = val;
        },
    },
};

function initCanvas($$$router, $$$loaded) {
    // console.log("\n\n>> canvas Initiated..", "click", $$$deactivateCanvasClick);
    console.log("\n\n>> canvas Initiated..", "hyperDiamond mode:", $$$deactivateCanvasClick);
    const p5script = function (p5) {
        // var zoom = 10
        // const maxZoom = 20
        // const minZoom = 1

        var debug = true;

        const debugLog = function (...args) {
            if (debug) {
                console.log(...args);
            }
        };
        debugLog(">> messages ", messages);

        // var zoom = 200
        var zoom = window.innerWidth / 3;
        // const centerZoom = 200; // ! // TODO use
        // const maxZoom = 230;
        // const maxZoom = 530;
        var maxZoom = window.innerWidth / 2;
        // const minZoom = 80;
        const minZoom = 100;
        var grid = [];

        // var gridCols = 10
        // var gridRows = 10
        var gridCols = 12;
        var gridRows = 5;
        // ? squareRoot of the total message items count
        var sqrRoot = 0;

        var items = messages;

        var mouseDragging = false;
        var offsetX = 0;
        var offsetY = 0;

        var translationX;
        var translationY;

        var speedX = 0;
        var speedY = 0;

        var newZoom = 0;

        var deceleration = 0.5;
        var mouseBlob;
        var mouseBlobSize = 100;

        var greenStrokeWeight = zoom * 0.05;
        var blackStrokeWeight = 0.02 * zoom;
        var whiteStrokeWeight = 0.01 * zoom;

        const backgroundGray = [34, 31, 29];
        // const backgroundGreen = [68, 193, 108]
        const backgroundGreen = [50, 200, 50];

        var isLoading = true && !$$$loaded;

        p5.setup = async () => {
            var canvas = p5.createCanvas(window.innerWidth, window.innerHeight);
            canvas.id("p5Canvas");
            canvas.parent("exploreCanvas");

            setupGrid();

            debugLog(">> grid", grid);

            //
            translationX = offsetX + (p5.width - zoom * gridCols) / 2;
            translationY = offsetY + (p5.height - zoom * gridRows) / 2;

            // mouseBlob = new MouseBlob()
            // ? mouseBlob DOM Object

            mouseBlob = p5.select("#mouseBlob");

            // mouseBlob.style('background', 'green')
            // mouseBlob.style('background', 'rgba(50, 200, 50, .8)')
            mouseBlob.style("z-index", "99999 !important");
            mouseBlob.style("transition", "color .3s ease-out");

            mouseBlob.style("border-radius", "70% 30% 30% 70% / 60% 40% 60% 40%");
            mouseBlob.style("display", "flex");
            mouseBlob.style("justify-content", "center");
            mouseBlob.style("text", "center");
            mouseBlob.style("font-size", "25px");
            mouseBlob.style("font-weight", "900");
            // mouseBlob.style("color", "black");
            mouseBlob.style("color", "rgb(50, 200, 50)");
            mouseBlob.style("align-items", "center");
            mouseBlob.style("animation", "morph 3s linear infinite");

            mouseBlob.position(p5.windowWidth / 2, p5.windowHeight / 2);

            var style = p5.createElement("style");
            style.type = "text/css";
            var keyFrames = `
                @keyframes morph{
                0%, 100% {
                border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
                }
                34% {
                    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
                }
                67% {
                    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
                }
            }`;
            style.html(keyFrames);
            p5.select("head").child(style);
            // TODO add kanvas parent
            p5.select("#exploreCanvas").style("cursor", "none");
            p5.select("#mouseBlob").style("cursor", "none");
            // p5.select("body").style("cursor", "none")

            await sleep(2000);
            isLoading = false;
        };

        // Setup images grid
        function setupGrid() {
            sqrRoot = p5.ceil(p5.sqrt(items.length));
            gridCols = sqrRoot;
            gridRows = sqrRoot;

            if (sqrRoot * sqrRoot != items.length) {
                // split in 2 to see
                gridRows = sqrRoot;
                gridRows = p5.max(items.length % sqrRoot, 1);

                var division = items.length / sqrRoot;
                division = p5.ceil(division);
                if (division != 1) {
                    gridRows = division;
                }
            }
            // debugLog(">>< kanvas ln");
            // debugLog("length", items.length);
            // debugLog("cols", sqrRoot);
            // debugLog("rows", gridRows);
            // debugLog("length/cols", division);
            // debugLog("length mod cols", items.length % sqrRoot);
            grid = [];
            for (var x = 0; x < gridCols; x++) {
                for (var y = 0; y < gridRows; y++) {
                    var index = x + y * sqrRoot;
                    var item = null;
                    item = items[index];
                    // grid.push(new Cell(x, y, loadImage("./images/thnos.jpg"), texts[x % texts.length]));

                    if (item) {
                        /// Load Image
                        // Create image
                        let img = p5.createImg(item.image);
                        // let img = p5.loadImage(item.image);
                        img.hide();
                        img.crossOrigin = "";   // ask for CORS permission
                        // grid.push(new Cell(x, y, p5.loadImage(item.image), item.id));
                        grid.push(new Cell(x, y, img, item.id));
                    }
                }
            }
        }

        // Keep kanvas full width and full height
        p5.windowResized = () => {
            p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
        };

        var loadingWidth = 10;
        var loadingX = 0;
        var rate = 10;

        p5.draw = () => {
            p5.background(backgroundGray);
            // ? loading animation
            if (isLoading) {
                p5.push();
                // ? Green thicc line that slides oscilates
                // p5.rect(0, 0, p5.width, 10)
                p5.fill(backgroundGreen);
                p5.rect(loadingX, 0, loadingWidth + 0.5 * loadingX, 10);
                if (loadingWidth > p5.width - 50 || loadingWidth < 0) {
                    rate *= -1;
                }
                if (loadingX > p5.width) {
                    loadingX = 0;
                }

                loadingWidth += rate;
                loadingX += rate;
                p5.pop();
                return;
            }
            mouseBlobSize = p5.min(0.5 * zoom + 10, 130);

            styleMouse();

            p5.noFill();
            // if (mouseDragging) {
            //     offsetX += p5.mouseX;
            //     // offsetY += p5.mouseY
            // }
            resetTranslation();

            if (!isLoading) {
                // ! draw Images grid
                drawImagesGrid();

                // Directional movement management
                keyPressDirectionalMovement();

                // drag slide animation
                if (Math.abs(speedX) > 0 || Math.abs(speedY) > 0) {
                    offsetX += speedX;
                    offsetY += speedY;
                    // TODO implement
                    // if(offsetX > window.innerHeight || offsetX < 0){
                    //     offsetX -= speedX;
                    // }
                    // if(offsetY > window.innerHeight || offsetY < 0){
                    //     offsetY -= speedY;
                    // }
                    // debugLog(">> sliddde >>", offsetX, offsetY);
                }

                if (newZoom > 0) {
                    if (zoom > newZoom) {
                        zoom -= deceleration;
                    } else if (zoom < newZoom) {
                        zoom += deceleration;
                    } else {
                        newZoom = 0;
                    }
                }

                // x deceleration
                if (speedX > 0) {
                    speedX -= deceleration;
                } else if (speedX < 0) {
                    speedX += deceleration;
                }

                // y deceleration
                if (speedY > 0) {
                    speedY -= deceleration;
                } else if (speedY < 0) {
                    speedY += deceleration;
                }

                // deceleration management
                // if (speedX != 0 || speedY != 0) {
                // deceleration -= 0.01;
                // if (deceleration < 0 || speedX == 0 && speedY == 0) {
                //     deceleration = 0.5;
                // }

                // }
            }
        };

        function drawImagesGrid() {
            p5.push();
            p5.translate(translationX, translationY);
            // draw grid
            for (var i = 0; i < grid.length; i++) {
                grid[i].draw();
            }
            p5.pop();
        }

        function sleep(millisecondsDuration) {
            return new Promise((resolve) => {
                setTimeout(resolve, millisecondsDuration);
            });
        }

        function resetTranslation() {
            translationX = offsetX + (p5.width - zoom * gridCols) / 2;
            translationY = offsetY + (p5.height - zoom * gridRows) / 2;
        }

        // Zoom on scrool
        p5.mouseWheel = (event) => {
            //move the square according to the vertical scroll amount
            var value = zoom + event.delta;
            if (value < minZoom || value > maxZoom) {
                return false;
            }

            debugLog(">> scroll");
            resetTranslation();
            zoom = value;
        };

        // function resetToCenter(){
        //     offsetX = 0
        //     offsetY = 0
        //     newZoom = centerZoom
        //     resetTranslation()
        // }

        // ? Key pressed
        // ? Directional movement
        function keyPressDirectionalMovement() {
            var rate = 1;

            //
            if (p5.keyIsDown(p5.LEFT_ARROW)) {
                speedX += rate;
            }
            if (p5.keyIsDown(p5.UP_ARROW)) {
                speedY += rate;
            }
            if (p5.keyIsDown(p5.RIGHT_ARROW)) {
                speedX += -rate;
            }
            if (p5.keyIsDown(p5.DOWN_ARROW)) {
                speedY += -rate;
            }
        }

        // drag
        p5.mouseDragged = (evt) => {
            // mouseDragging = false;
            // * debugLog(">> dragged", evt.movementX, evt.movementY);
            // if (evt.movementX != 0 || evt.movementY != 0) {
            //     mouseDragging = true;
            // }
            // if (evt.offsetX > translationX && evt.offsetX < translationX + gridCols * zoom) {
            // debugLog(">> inside", evt.movementX, evt.offsetX, gridCols*zoom);
            speedX = evt.movementX;
            speedY = evt.movementY;
            // * debugLog(">> slide");
            //
            // } else {
            // ! // TODO implement spring physics to bounce back
            // debugLog(">> slide bounce");
            // speedX = evt.movementX;
            // speedY = evt.movementY;
            // }
            // check if draggind

            // return false;
        };
        p5.mousePressed = () => {
            // mouseDragging = true
            debugLog(">> pressed");
            var deltaX = Math.abs(p5.mouseX - p5.pmouseX);
            var deltaY = Math.abs(p5.mouseY - p5.pmouseY);
            mouseDragging = deltaX < 10 && deltaY < 10;
            // * debugLog(">> clicked X", p5.mouseX, p5.pmouseX);
            // * debugLog(">> clicked Y", p5.mouseY, p5.pmouseY);
            // * debugLog(">> clicked delta", deltaX, deltaY);
            if (p5.mouseIsPressed) triggerMouseClick(p5.mouseX, p5.mouseY);
            if (mouseDragging);
            console.log("");
            return;
        };

        p5.mouseReleased = () => {
            // mouseDragging = false
            // mouseDragging = false;
            // * debugLog(">> released");
        };
        //
        p5.mouseClicked = () => {};

        // mouseClick management function
        // in case ya wanna trigger it from let's say space key
        function triggerMouseClick(mouseX, mouseY) {
            if (!$$$deactivateCanvasClick) {
                // debugLog("<<>>click<<>>", window.location);
                if (window.location.pathname.indexOf("explore") >= 0) {
                    debugLog("<<+>>click<<+>>", mouseX, mouseY, window.location);
                    // find specific grid item
                    
                    for (var i = 0; i < grid.length; i++) {
                        if(grid[i].mouseEnter()){
                            grid[i].mouseClicked();
                        }
                    }
                }
            }
        }

        function Cell(x, y, img, word) {
            this.x = x;
            this.y = y;
            this.img = img;
            this.word = word;
            this.hovering = false;
            this.setup = false;

            // ? stroke weights
            /*
        /// when the zoom is 200 greenStrokeWeight => 10
        /// const greenStrokeWeight = 10;
        /// const greenStrokeWeight = .05 * zoom;
        /// when the zoom is 200 blackStrokeWeight => 7
        /// const blackStrokeWeight = 10 - 3;
        */

            this.draw = () => {
                greenStrokeWeight = zoom * 0.05;
                blackStrokeWeight = 0.02 * zoom;

                p5.rect(this.x * zoom, this.y * zoom, zoom);

                var imgSize = zoom;
                var imgX = this.x * zoom;
                var imgY = this.y * zoom;
                // on HOVER Scale down image
                if (this.mouseEnter()) {
                    imgSize = zoom - whiteStrokeWeight - greenStrokeWeight - blackStrokeWeight * 4;
                    imgX += (whiteStrokeWeight + greenStrokeWeight + blackStrokeWeight * 4) / 2;
                    imgY += (whiteStrokeWeight + greenStrokeWeight + blackStrokeWeight * 4) / 2;
                }
                
                p5.image(this.img, imgX, imgY, imgSize, imgSize);

                // on HOVER draw contour
                if (this.mouseEnter()) {
                    this.drawContour();
                }
            };
            // ?? Draws 3 contours : 7px black 10px green 7px black
            this.drawContour = () => {
                /// ! // To my future self or whoever finds this repo
                /// ? If you wish to add another contour layer
                /// ? just do :
                /// ? x= x*zoom +...previousLayersStrokeWeights+  layerStrokeWeight/2
                /// ? y= y*zoom +...previousLayersStrokeWeights+  layerStrokeWeight/2
                /// ? size = zoom - (...previousLayersStrokeWeights)*2 - layerStrokeWeight
                p5.push();
                p5.noFill();
                // white narrow outline
                p5.strokeWeight(whiteStrokeWeight);
                p5.stroke(255);

                // ! // TODO refractor & document
                p5.rect(this.x * zoom + whiteStrokeWeight / 2, this.y * zoom + whiteStrokeWeight / 2, zoom - whiteStrokeWeight);

                // black outline
                p5.strokeWeight(blackStrokeWeight);
                p5.stroke(backgroundGray);
                // ! // TODO refractor & document
                p5.rect(
                    this.x * zoom + whiteStrokeWeight + blackStrokeWeight / 2,
                    this.y * zoom + whiteStrokeWeight + blackStrokeWeight / 2,
                    zoom - whiteStrokeWeight * 2 - blackStrokeWeight
                );

                // green outline
                p5.stroke(backgroundGreen);
                p5.strokeWeight(greenStrokeWeight);
                p5.rect(
                    this.x * zoom + whiteStrokeWeight + blackStrokeWeight + greenStrokeWeight / 2,
                    this.y * zoom + whiteStrokeWeight + blackStrokeWeight + greenStrokeWeight / 2,
                    zoom - whiteStrokeWeight * 2 - blackStrokeWeight * 2 - greenStrokeWeight
                );

                // black outline
                p5.stroke(backgroundGray);
                p5.strokeWeight(blackStrokeWeight);
                p5.rect(
                    this.x * zoom + whiteStrokeWeight + blackStrokeWeight * 2 + greenStrokeWeight / 2,
                    this.y * zoom + whiteStrokeWeight + blackStrokeWeight * 2 + greenStrokeWeight / 2,
                    zoom - whiteStrokeWeight * 2 - greenStrokeWeight - blackStrokeWeight * 4
                );

                /// ! // To my future self or whoever finds this repo
                /// ? If you wish to add another contour layer
                /// ? just do :
                /// ? x= x*zoom +...previousLayersStrokeWeights+  layerStrokeWeight/2
                /// ? y= y*zoom +...previousLayersStrokeWeights+  layerStrokeWeight/2
                /// ? size = zoom - ...previousLayersStrokeWeights - layerStrokeWeight
                p5.pop();

                // TODO center text on image
                mouseBlob.html(this.word);
                // Highligth mouse
                mouseBlob.style("border", "2px solid rgba(50, 200, 50, .8)");
                mouseBlob.style("background", "rgba(0, 0, 0, .6)");
            };

            this.mouseClicked = () => {
                // debugLog(">>> router", this.$$$router);

                // if (this.mouseEnter()) {
                    debugLog(">>> mouse clicked", p5.mouseX, p5.mouseY);
                    // debugLog(">>> router", $$$router);
                    // Select this item
                    // $$$router.push('/explore?id='+ this.word)
                    $$$router.push({ name: "Explore", query: { id: this.word } });
                    // const event = new CustomEvent('messageTileSelected', this.word)
                    // document.dispatchEvent(event)
                // }
            };
            // mouse is hovering
            this.mouseEnter = () => {
                return (
                    p5.mouseX > translationX + this.x * zoom &&
                    p5.mouseX < translationX + this.x * zoom + zoom &&
                    p5.mouseY > translationY + this.y * zoom &&
                    p5.mouseY < translationY + this.y * zoom + zoom
                );
            };
        }

        // ? Centers mouseBlob on mouse and empties mouseBlob's content
        function styleMouse() {
            mouseBlob.style("border", "none");
            mouseBlob.style("background", "rgb(0, 0, 0)");
            mouseBlob.style("width", mouseBlobSize + "px");
            mouseBlob.style("height", mouseBlobSize + "px");
            mouseBlob.position(p5.mouseX - mouseBlobSize / 2, p5.mouseY - mouseBlobSize / 2);
            mouseBlob.html("");
        }
    };
    new P5(p5script);
}
</script>
